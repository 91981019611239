import { VegaThemeManager } from '@heartlandone/vega';
import Image from 'next/image';

import styles from './logo.module.css';

interface Props {
  height: number;
  width: number;
}

const HeartlandLogo: React.FC<Props> = ({ height, width }) => {
  let logoStyle = styles.logo;
  logoStyle = VegaThemeManager.isDarkMode() ? styles.logoInv : styles.logo;

  return (
    <Image
      alt="heartlandlogo"
      height={height}
      width={width}
      priority
      className={logoStyle}
      data-testid="heartlandLogo"
      src={process.env.NEXT_PUBLIC_IMAGE_URL + '/heartlandLogoGray.svg'}
    />
  );
};

export default HeartlandLogo;
