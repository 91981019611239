import React from 'react';

import { VegaBox } from '@heartlandone/vega-react';

import styles from 'components/loginViews/loginViews.module.css';

interface ThemeWrapperProps {
  children: any;
  noWrap?: boolean;
}
const ThemeWrapper = ({ children, noWrap }: ThemeWrapperProps) => {
  if (noWrap) return children;
  return <VegaBox className={styles.wrapperContainer}>{children}</VegaBox>;
};

export default ThemeWrapper;
