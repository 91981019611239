import React from 'react';

import LoginSplash from 'components/loginViews/loginSplash';
import ThemeWrapper from 'components/loginViews/themeWrapper';

const AppLoading = () => {
  return (
    <ThemeWrapper noWrap>
      <LoginSplash />
    </ThemeWrapper>
  );
};

export default AppLoading;
